import { createSvgIcon } from '@mui/material';
import React from 'react';

export const Gameloop = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 22 12"
    stroke="none"
    fill="currentColor"
  >
    <path
      d="M9.96889 4.81731C9.63926 4.26487 8.36292 3.0977 7.77665 2.78319C7.19152 2.47786 6.51514 2.32571 5.74865 2.32571C4.98217 2.32571 4.32175 2.47786 3.73662 2.78319C3.15149 3.0977 2.69867 3.53169 2.3793 4.08413C2.04967 4.63657 1.88428 5.27581 1.88428 5.9998C1.88428 6.7238 2.04967 7.36303 2.3793 7.91548C2.69867 8.46792 3.15149 8.8968 3.73662 9.20212C4.32175 9.51663 4.99243 9.67389 5.74865 9.67389C6.51514 9.67389 7.19152 9.51663 7.77665 9.20212C8.36178 8.8968 8.8203 8.46792 9.14994 7.91548L11.2053 3.26925C11.6844 2.47786 12.3607 1.86313 13.2333 1.42506C14.1059 0.996179 15.1073 0.781738 16.2354 0.781738C17.3634 0.781738 18.3809 0.996179 19.2534 1.42506C20.126 1.86313 20.8024 2.47786 21.2814 3.26925C21.7605 4.06064 22 4.97049 22 5.9998C22 7.02912 21.7605 7.93896 21.2814 8.73035C20.8024 9.52174 20.126 10.1314 19.2534 10.5602C18.3809 10.9983 17.3748 11.2179 16.2354 11.2179C15.1073 11.2179 14.1059 10.9983 13.2333 10.5602C12.3607 10.1314 11.6844 9.52174 11.2053 8.73035L12.0733 7.20578C12.3927 7.75822 13.6371 8.8968 14.2234 9.20212C14.8085 9.51663 15.4792 9.67389 16.2354 9.67389C17.0019 9.67389 17.6782 9.51663 18.2634 9.20212C18.8485 8.8968 19.307 8.46792 19.6367 7.91548C19.956 7.36303 20.1157 6.7238 20.1157 5.9998C20.1157 5.27581 19.956 4.63657 19.6367 4.08413C19.307 3.53169 18.8485 3.0977 18.2634 2.78319C17.6782 2.47786 17.0019 2.32571 16.2354 2.32571C15.4689 2.32571 14.8085 2.47786 14.2234 2.78319C13.6382 3.0977 13.1854 3.53169 12.866 4.08413L10.7947 8.73035C10.3156 9.52174 9.63926 10.1314 8.76669 10.5602C7.89413 10.9983 6.88812 11.2179 5.74865 11.2179C4.60919 11.2179 3.61914 10.9983 2.74658 10.5602C1.87401 10.1314 1.19764 9.52174 0.718581 8.73035C0.239527 7.93896 0 7.02912 0 5.9998C0 4.97049 0.239527 4.06064 0.718581 3.26925C1.19764 2.47786 1.87401 1.86313 2.74658 1.42506C3.61914 0.996179 4.62059 0.781738 5.74865 0.781738C6.87671 0.781738 7.89413 0.996179 8.76669 1.42506C9.63926 1.86313 10.3156 2.47786 10.7947 3.26925L9.96775 4.81731H9.96889Z"
      // fill="#1A1A1A"
    />
  </svg>,
  'Gameloop'
);
