import React from 'react';
import { createSvgIcon } from '@mui/material';

const Usdce = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    stroke="none"
    fill="currentColor"
  >
    <path
      d="M10.4525 8.38033C10.4525 7.01943 9.63598 6.55283 8.00286 6.35844C6.83635 6.20288 6.60305 5.89184 6.60305 5.34741C6.60305 4.80297 6.99192 4.45311 7.76956 4.45311C8.46947 4.45311 8.85833 4.68642 9.05272 5.26967C9.09164 5.38632 9.20829 5.46406 9.32494 5.46406H9.94701C10.1026 5.46406 10.2192 5.34741 10.2192 5.19193V5.15302C10.0637 4.29755 9.36376 3.63656 8.46947 3.55882V2.62561C8.46947 2.47005 8.35282 2.3534 8.15843 2.31448H7.57517C7.41961 2.31448 7.30296 2.43113 7.26404 2.62561V3.51991C6.09753 3.67547 5.35881 4.45311 5.35881 5.42524C5.35881 6.7084 6.13645 7.21382 7.76956 7.4083C8.85833 7.60269 9.20829 7.83599 9.20829 8.45816C9.20829 9.08033 8.66385 9.50802 7.92513 9.50802C6.91409 9.50802 6.56414 9.08023 6.44749 8.49698C6.40867 8.34151 6.29201 8.26368 6.17536 8.26368H5.51428C5.35881 8.26368 5.24216 8.38033 5.24216 8.53589V8.57481C5.39763 9.54684 6.0198 10.2467 7.30296 10.4412V11.3744C7.30296 11.5299 7.41961 11.6466 7.614 11.6855H8.19725C8.35282 11.6855 8.46947 11.5688 8.50838 11.3744V10.4412C9.67489 10.2467 10.4525 9.43019 10.4525 8.38033Z"
      // fill="#F8F8F8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.43387 4.98314C1.30923 7.96915 2.8604 11.3429 5.88512 12.4286C6.00146 12.5062 6.11779 12.6613 6.11779 12.7776V13.3206C6.11779 13.3981 6.11779 13.4369 6.07898 13.4756C6.04027 13.6308 5.88512 13.7083 5.72998 13.6308C3.55841 12.9328 1.89091 11.2653 1.1929 9.09369C0.0295465 5.40977 2.04606 1.49309 5.72998 0.329732C5.76879 0.291016 5.84631 0.291016 5.88512 0.291016C6.04027 0.329732 6.11779 0.446067 6.11779 0.601212V1.14408C6.11779 1.33803 6.04027 1.45437 5.88512 1.53189C4.29524 2.11357 3.01555 3.35445 2.43387 4.98314ZM9.64851 0.484564C9.68722 0.329419 9.84237 0.251893 9.99751 0.329419C12.1303 1.02743 13.8366 2.69493 14.5346 4.9053C15.6979 8.58922 13.6814 12.5059 9.99751 13.6693C9.9587 13.708 9.88118 13.708 9.84237 13.708C9.68722 13.6693 9.6097 13.5529 9.6097 13.3978V12.8549C9.6097 12.661 9.68722 12.5446 9.84237 12.4671C11.4323 11.8854 12.7119 10.6445 13.2936 9.01585C14.4183 6.02985 12.8671 2.65612 9.84237 1.57039C9.72603 1.49277 9.6097 1.33772 9.6097 1.18258V0.639709C9.6097 0.56209 9.6097 0.523373 9.64851 0.484564Z"
      // fill="#F8F8F8"
    />
  </svg>,
  'Usdce'
);

export default Usdce;
