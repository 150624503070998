import { createSvgIcon } from '@mui/material';
import React from 'react';

export const Logo = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="24"
    viewBox="0 0 32 24"
    stroke="none"
    fill="currentColor"
  >
    <path
      d="M11.9988 14.5216C10.6251 14.5216 9.50801 13.4117 9.50801 12.0472C9.50801 10.6827 10.6251 9.57283 11.9988 9.57283H26.9232V6.6618H11.9988C9.02041 6.6618 6.59698 9.07795 6.59698 12.0472C6.59698 15.0164 9.02041 17.4326 11.9988 17.4326H31.9993V14.5216H11.9988Z"
      // fill="#1A1A1A"
    />
    <path
      d="M11.9789 0.0648193C5.37265 0.0648193 0 5.41928 0 12C0 18.5807 5.37265 23.9352 11.9789 23.9352H31.9521V21.0242H11.9789C6.97917 21.0242 2.91102 16.976 2.91102 12C2.91102 7.02398 6.97917 2.97584 11.9789 2.97584H31.9521V0.0648193H11.9789Z"
      // fill="#1A1A1A"
    />
    <path
      d="M30.2715 6.6618C29.4135 6.6618 28.7191 7.35625 28.7191 8.2142C28.7191 9.07216 29.4135 9.7666 30.2715 9.7666C31.1294 9.7666 31.8239 9.07216 31.8239 8.2142C31.8239 7.35625 31.1294 6.6618 30.2715 6.6618Z"
      // fill="#1A1A1A"
    />
  </svg>,
  'Logo'
);
